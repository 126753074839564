<template>
	<div class="detailPartner pl">
		<el-card>
			<div class="flex_r f_jc_b account p20">
				<div class="flex_r f_ai_c pl10">
					<div class="posr account_img">
						<img class="brs_4" height="88" width="88" :src="seller.avatar" alt="">
					</div>
					<div class="flex_c f_jc_c pl15">
						<span class="pb10 fs18">{{seller.username}}</span>
						<span class="el-icon-phone">{{seller.mobile}}</span>
						<span class="el-icon-user-solid">{{seller.qq}}</span>
					</div>
				</div>
				<ul class="flex_r pr20">
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">总销量</span>
						<span class="pt10 fs16">{{seller.cjs}}</span>
					</li>
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">总销售额</span>
						<span class="pt10 fs16">{{seller.xse}}</span>
					</li>
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">合作次数</span>
						<span class="pt10 fs16">{{seller.zbTimes}}</span>
					</li>
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">商品数</span>
						<span class="pt10 fs16">{{seller.goodsNum}}</span>
					</li>
					<li class="flex_c f_jc_c">
						<span class="pb10">招商人：</span>
						<div class="felx_r f_ai_c">
							<el-select disabled>
								<el-option label="1" value="1"></el-option>
							</el-select>
							<el-button>编辑</el-button>
						</div>
					</li>
				</ul>
			</div>
		</el-card>
		<el-row :gutter="10">
			<el-col :span="12">
				<e-card title="主播销售额占比">
					<div slot="title">
					</div>
					<div class="item">
						<e-charts id="idPie" :options="optionPie" class="h100" />
						<!-- <Empty /> -->
					</div>
				</e-card>
			</el-col>
			<el-col :span="12">
				<e-card title="商品销售额占比">
					<div slot="title">
					</div>
					<div class="item">
						<e-charts id="idPie2" :options="optionPie" class="h100" />
						<!-- <Empty /> -->
					</div>
				</e-card>
			</el-col>
		</el-row>
		<div class="fs16 mt30 mb30">合作商品数<span class="cr_ff0">{{total}}</span>个</div>
		<e-table ref="tables" @query-Table-data="handleCurrentChange" :tableCols="detailPartnerTableConf" :dataOrigin="dataOrigin">
			<template #account1="scope">
				<div class="flex_r f_ai_c">
					<img height="60" width="60" :src="scope.rowData.pict_url" alt="">
					<div class="pl10">
						<span>{{scope.rowData.title}}</span>
						<div>
							<img width="10" height="10" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/tm.png" alt="" srcset="">
							<span>{{scope.rowData.nick}}</span>
						</div>
					</div>
				</div>
			</template>
			<template #account6="scope">
				<div class="flex_r f_ai_c">
<!--					<img height="60" width="60" src="https://filenew.lxshuju.com/000000/zanwutupian.png" alt="">-->
					<span>{{scope.rowData.zbtime}}</span>
				</div>
			</template>
			<template #operation="scope">
				<router-link tag="a" target="_blank" :to="{path:'/commodityDetails',query: {id:scope.rowData.cgid}}">查看详情</router-link>
				<div class="cur_p">邀请报名</div>
			</template>
		</e-table>
	</div>
</template>

<script>
import { optionPie} from '@data/dashboard'
import {detailPartnerTableConf} from '@data/business'
export default {
	name: 'DetailPartner',
	data() {
		return {
			detailPartnerTableConf,
			optionPie,
			dataOrigin: {
				data: []
			},
			seller: {},
			id: '',
			total: 0,
			currentPage: 1,
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetDetail()
		},
		GetDetail(){
			let that = this
			this.dataOrigin.data = []
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			this.get('?server=live.seller.detail.info.get',{id:this.id,page:this.currentPage}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				// that.dataOrigin.data = response.data.data
				let len = response.data.lists.data.length
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.lists.data[i])
				}
				that.seller = response.data.seller
				that.total = response.data.lists.total
				that.$refs.tables.total = response.data.lists.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.id = this.$route.query.id

		if(!this.id){
			this.$message.error('请求非法')
		} else {
			this.GetDetail()
		}
	}
}
</script>

<style lang="scss" scoped>
.account {
	height: 88px;
	.account_img {
		span {
			height: 20px;
			left: 0;
			right: 0;
			bottom: 1px;
			line-height: 20px;
			background-color: rgba($color: #000000, $alpha: .1);
		}
	}
	li {
		height: 88px;
		padding-left: 50px;
	}
}
.echart {
	height: 200px;
}
.item {
	height: 200px;
}
.b_num {
	margin: 0 auto;
	width: 140px;
    height: 48px;
    background: #f9f9f9;
    border-radius: 24px;
    color: #999;
    font-size: 12px;
}
.time_row {
	line-height: 34px;
	border-bottom: 1px solid #eee;
	&.head {
		background-color: rgb(246, 249, 253);
		line-height: 30px;
	}
	span {
		width: 33.33%;
		text-align: center;
	}
}
.line {
	height: 150px;
}
</style>

<style lang="scss">
.el-card.is-always-shadow {
	box-shadow: 0 0px 5px 0 rgb(0 0 0 / 6%);
}
.el-card__body {
	padding: 10px;
}
.h100 {
	height: 100%;
}
</style>